$color__white: #fff;
$color__black: #282828;
$color__grey--primary: #e5e5e5;
$color__grey--secondary: #afafaf;
$color__grey--tertiary: #6e6e6e;
$color__grey--light: #737373;
$color__grey--background: #171717;
$color__grey--border: #dcdcdc;
$color__blue: #0074D9;
$color__red--logo: #fa4c25;
$color__red--barnes: #d64220;
$color__red--barnes-dark: #b3371b;
$color__success: #0f0;
$color__warn: #ff0;