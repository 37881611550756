@import '../variables.scss';

.instruction {
    $self: &;

    background-color: $color__grey--primary;

    font-family: FFMiloSerifWebText, serif;
    font-size: 19px;
    line-height: 1.57895;

    display: flex;
    flex-direction: column;

    justify-content: center;

    &__logos {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        margin-bottom: 10px;

        &--apple-active {
            #{ $self } {
                &__logo {
                    color: $color__grey--secondary;

                    &--apple {
                        color: $color__grey--background;
                    }
                }
            }
        }
    }

    &__logo-slash {
        font-size: 46px;
        margin-top: 1px;
        display: inline-block;
    }

    &__logo {
        color: $color__grey--background;

        width: 40px;
        height: 40px;

        line-height: 32px;

        transition: color 2s;

        svg {
            transition: color 2s;
        }

        &--apple {
            color: $color__grey--secondary;
        }

        &--android {
            margin-top: 3px;
            margin-right: 3px;
        }
    }
}

.instruction-card {
    // color: $color__grey--secondary;
    color: $color__black;

    transition: opacity 1s ease;

    max-height: 100%;
    width: 70%;
    
    position: absolute;
    top: 20px;
    left: 15%;

    &__wrapper {
        position: relative;
        height: 100%;
    }

    &__video-wrapper {
        position: relative;

        width: 100%;
        margin-bottom: 10px;
    }

    &__video {
        width: 100%;
        z-index: 1;

        border-radius: 7px;
    }

    &__description {
        width: 100%;
    }
}