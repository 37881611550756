@import "../variables.scss";
@import "../mixins.scss";

.capacity {
    display: flex;
    height: 100%;
    flex-direction: column;

    &__header-wrapper {
        padding: 10px 60px 0;
    }

    &__description {
        padding: 10px 60px;
        margin: 0px 15px;
    }

    &__description-header {
        font-size: 38px;
        line-height: 45px;
        font-weight: 700;
        margin: 0;
        margin-top: 20px;

        &--sub {
            margin-top: 0;
            color: $color__grey--light;
        }
    }

    &__content {
        margin-top: 20px;
        margin-bottom: auto;
        padding-bottom: 150px;
    }

    &__cards {
        margin: 0 10px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        transition: opacity 0.5s;
    }

    &__spinner {
        transition: opacity 1s;

        div {
            width: 24px !important;
            height: 24px !important;
        }
    }

    &__progress-card {
        margin: 0 5px 10px;
        width: 250px;
        height: 400px;
        display: flex;
        align-items: center;
        flex-direction: column;

        padding-top: 20px;

        background-color: $color__grey--primary;
        border: 1px solid $color__grey--border;
    }

    &__caption-wrapper {
        width: 100%;
        box-sizing: border-box;
        padding: 10px 20px;

        transition: opacity 1s;
    }

    &__caption {
        margin-top: 15px;
        width: 100%;
        box-sizing: border-box;

        border-bottom: 1px solid $color__grey--secondary;

    }

    &__caption-header {
        margin: 0;
        font-weight: 700;
        font-size: 25px;
        line-height: 1.2;
    }

    &__caption-bump {
        @include zeta();
    }

    &__caption-stats {
        margin-top: 15px;

        font-family: FFMiloSerifWebText, serif;
        font-size: 19px;
        line-height: 1.57895;

        p {
            margin: 0;
        }

        padding-bottom: 20px;
        margin-bottom: 5px;
    }
}

/*
* Fill color of progress circle.
*/
@mixin fillColor($self, $color) {
    #{ $self } {
        &__circle {
            &--progress {
                stroke: $color;
            }
        }

        &__wave {
            color: mix(black, $color, 20);
        }
    }
}

.progress-ring {
    $self: &;
    
    position: relative;
    border-radius: 50%;

    width: 200px;
    height: 200px;
    overflow: hidden;

    @include fillColor($self, $color__success);

    &--error {
        @include fillColor($self, $color__red--barnes);
    }

    &--warn {
        @include fillColor($self, $color__warn);
    }

    &__caption {
        position: absolute;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        transition: opacity 1s;
    }

    &__caption-text {
        display: inline-block;
        text-align: center;

        color: $color__white;
        font-size: 26px;

        &--percent {
            font-size: 48px;
        }

        &--bump {
            font-size: 40px;
        }

        &--over {
            display: inline-block;
            width: 40%;
            padding-top: 2px;
            border-top: 2px $color__white solid;
        }
    }

    &__circle {
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        transition: stroke-dashoffset 0.5s;

        stroke-width: 5;

        &--background {
            fill: mix(black, $color__grey--primary, 20);
        }

        &--base {
            stroke: $color__grey--secondary;
        }
    }

    &__wave {
        path {
            transition: transform 0.5s;
        }
    }
}

