.signage {
    background-color: #fff;

    font-family: Calibre, sans-serif;

    width: 100vw;
    height: 100vh;

    display: grid;
    grid-template-columns: 13fr 7fr;

    overflow: hidden;

    &__child {
        padding: 10px 60px;
    }
}