@import "../variables.scss";

.header {
    width: 100%;

    height: $header-height;
    
    &__barnes-logo {
        color: $color__red--logo;
        height: 70%;

        margin: 20px 15px 30px;
    }
}
