.admin {
    background-color: #fff;
    font-family: Calibre, sans-serif;
    padding: 10px 60px 0;

    height: 100vh;
}

.content-wrapper {
    display: flex;
    
    justify-content: center;
    align-items: flex-start;
}

.content {
    display: grid;
    grid-template-rows: min-content min-content min-content;
    row-gap: 20px;

    width: 400px;

    &__select-header {
        margin: 0;
        font-weight: 700;
        font-size: 25px;
        line-height: 1.2;
    }

    &__select-item {
        display: flex;
        align-items: center;
        position: relative;
        height: 120px;
        ;

        cursor: pointer;
        font-size: 22px;
        user-select: none;

        border: 1px solid lightgray;
        padding-left: 30px;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .radio {
            position: absolute;

            height: 35px;
            width: 35px;
            background-color: #eee;
            border-radius: 50%;
        }

        &:hover input~.radio {
            background-color: #ccc;
        }

        input:checked~.radio {
            background-color: #d64220;
        }

        .radio:after {
            content: "";
            position: absolute;
            display: none;
        }

        input:checked~.radio:after {
            display: block;
        }

        .radio:after {
            top: 10px;
            left: 10px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: white;
        }

        .label {
            cursor: pointer;
        }

        .date {
            font-size: 14px;
        }

        .info {
            margin-left: 55px;
        }
    }
}

.save-btn {
	font-family: Calibre, sans-serif;
	font-size: 20px;
	cursor: pointer;

    padding: 15px 20px;
	width: 100px;
	
	justify-self: end;
	
	background-color: #d64220;
	border: 2px solid #dcdcdc;
	border-color: #d64220;
	color: #fff;
	outline: none;

}