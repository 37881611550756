@import '../variables.scss';
@import '../mixins.scss';


$header-height: 90px;

.event {
    background-color: $color__white;
    position: relative;

    // Content area with QR code.
    &__content {
        width: 100%;
        height: calc(100% - 100px);

        display: flex;
        justify-content: center;
        align-items: center;

        flex-direction: column;
    }

    &__detail-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &__detail-warn {
        font-size: 36px;
        margin-bottom: 200px;
    }

    &__footer {
        margin-top: auto;
        width: 100%;
    }

    &__footer-line {
        border-top: 1px solid $color__grey--border;
        width: 75%;
        margin-left: auto;
        margin-right: auto;

        margin-bottom: 20px;
    }

    &__footer-text {
        text-align: center;
        color: $color__grey--tertiary;
        font-size: 18px;

        display: block;
        margin-bottom: 20px;

        width: 100%;
    }
}

.event-card {
    width: 75%;
    color: $color__black;
    margin-top: 20px;

    &__caption {
        @include zeta();

        margin: 10px 0;
    }

    &__qr-wrapper {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }

    &__qr {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }

    &__header {
        margin: 0;
        font-weight: 700;
        font-size: 25px;
        line-height: 1.2;

        &--date {
            color: $color__red--barnes;
        }
    }

    &__description {
        margin-top: 15px;

        font-family: FFMiloSerifWebText, serif;
        font-size: 19px;
        line-height: 1.57895;

        & > p {
            margin: 0;

            &:first-of-type {
                margin-block-start: 1em;
            }

            &:last-of-type {
                margin-block-end: 1em;
            }
        }

        & > * {
            font-family: FFMiloSerifWebText, serif !important;
            font-size: 19px !important;
            line-height: 1.57895 !important;
        }
    }
}
